import { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import { config } from '../config';
import dynamic from 'next/dynamic';
import Footer from '../components/Footer';
import '../styles/globals.css';
import { LayoutGroup } from 'framer-motion';
import { Toaster } from 'react-hot-toast';

const Providers = dynamic(() => import('../components/Providers'), {
  ssr: false,
});

const Header = dynamic(() => import('../components/Header'), {
  ssr: false,
});

function MyApp({ Component, pageProps }) {
  // check if home page
  const router = useRouter();
  const isHome = router.pathname === '/';
  const description = `Gekofam is a community of investors and enthusiasts centered around the creations of art by Geko on the Algorand blockchain. As the name suggests, we are more than just collections, we are a family.`;

  return (
    <>
      <DefaultSeo
        title={`Welcome to the ${config.siteName}`}
        description={description}
        openGraph={{
          type: 'website',
          locale: 'en_IE',
          url: config.domain,
          site_name: config.siteName,
          images: [
            {
              url: `${config.domain}/twitter-share-image.png`,
            },
          ],
        }}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
      />
      <Providers>
        <Header />
        <LayoutGroup>
          <main>
            <Component {...pageProps} />
          </main>
        </LayoutGroup>
      </Providers>
      {!isHome && <Footer />}
      <Toaster position='bottom-right' />
    </>
  );
}

export default MyApp;
