export const config = {
  siteName: 'Gekofam',
  domain: 'https://gekofam-com.vercel.app',
  twitter: 'https://twitter.com/Gekofam',
  boganTwitter: 'https://twitter.com/boganmeister',
  discord: 'https://discord.gg/5cymMEbbbC',
  tokenId: 787168529,
  tokenUrl: 'https://algoxnft.com/asset/787168529',
  charityWallet: '3JJ2UUCTLRWDBA3Q25SWMQ6DVHYSRK3SKOVBFHUXCFDF6DZKZCMHYY7I3U',
};
